
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  computed,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { AxiosError } from 'axios';
import { base64decode } from 'nodejs-base64';

/** Interfaces */
import { CouponDataI } from '@/modules/coupon/interfaces/digital-coupon.interface';

/** Utils */
import {
  closeSwal,
  showSwalError,
  showSwalInfo,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import {
  handleCatchErrorCustom,
  addMinutesToDate,
} from '@/utils/general-utils';

export default defineComponent({
  name: 'CouponLink',
  components: {
    Credifamilia: defineAsyncComponent(
      () => import('@/modules/coupon/pages/Credifamilia.vue')
    ),
    CajaSocial: defineAsyncComponent(
      () => import('@/modules/coupon/pages/CajaSocial.vue')
    ),
    Iris: defineAsyncComponent(() => import('@/modules/coupon/pages/Iris.vue')),
    Listo: defineAsyncComponent(
      () => import('@/modules/coupon/pages/Listo.vue')
    ),
    ValidateAccess: defineAsyncComponent(
      () => import('@/modules/coupon/pages/ValidateAccess.vue')
    ),
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const url: string = window.location.href;
    const query = computed<string>(() => base64decode(`${route.query.q}`));
    const token = ref<string>('');
    const couponData = ref<CouponDataI>(Object.create({}));
    const showCoupon = ref<boolean>(false);
    const identification = ref<string>('');
    const loading = ref<boolean>(false);
    const errorOccurred = ref<boolean>(false);

    const convertQueryInJson = computed(() => {
      const searchParams = new URLSearchParams(query.value);
      searchParams.append('url', url);
      return Object.fromEntries([...searchParams]);
    });

    type CouponType = {
      [key: string]: string;
    };

    const couponComponentOptions = (id: string): string => {
      if (!showCoupon.value) {
        return 'ValidateAccess';
      }

      const coupons: CouponType = {
        '0': 'Listo',
        '1': 'Iris',
        '2': 'CajaSocial',
        '3': 'Credifamilia',
      };

      return coupons[id];
    };

    const setIdentification = (value: string) => {
      identification.value = value;
    };

    const customComponent = computed<string>(() => {
      setIdentification(String(convertQueryInJson.value?.identification));
      return couponComponentOptions(convertQueryInJson.value?.type);
    });

    const setAccessGranted = (value: boolean) => {
      showCoupon.value = value;

      if (!value) {
        showSwalInfo('El número de cédula digitado no es válido.');
      }
    };

    const getCouponData = async () => {
      loading.value = true;
      showSwalLoading();
      try {
        const payload = {
          date: addMinutesToDate(5),
          duration: 3,
        };

        const response = await store.dispatch('getPublicToken', payload);
        const { access_token } = response.data;
        if (access_token) {
          token.value = access_token;
          couponData.value = await store.dispatch('coupon/getDigitalCoupon', {
            params: convertQueryInJson.value,
            token: token.value,
          });
          closeSwal();
        } else {
          errorOccurred.value = true;
          showSwalError('Se produjo un error al consultar el recibo digital.');
        }
      } catch (error) {
        errorOccurred.value = true;
        handleCatchErrorCustom(
          error as AxiosError,
          'Se produjo un error al consultar el recibo digital.'
        );
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => showCoupon.value,
      (value: boolean) => {
        if (value) {
          getCouponData();
        }
      }
    );

    return {
      loading,
      couponData,
      showCoupon,
      errorOccurred,
      identification,
      customComponent,
      setAccessGranted,
    };
  },
});
