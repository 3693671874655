import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.loading && !_ctx.errorOccurred)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.customComponent), {
        key: 0,
        data: _ctx.couponData,
        identification: _ctx.identification,
        onSetAccessGranted: _ctx.setAccessGranted
      }, null, 8, ["data", "identification", "onSetAccessGranted"]))
    : _createCommentVNode("", true)
}